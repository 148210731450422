module Main exposing (init, main, sloganRequest, subscriptions, update)

import Browser exposing (document)
import Core exposing (..)
import Http exposing (emptyBody, expectString)
import Ports exposing (speakIt)
import Time
import Tuple exposing (second)
import View exposing (view)

second : Float
second = 1000.0

-- MODEL

type alias Flags =
    ()


init : Flags -> ( Model, Cmd Msg )
init _ =
    ( Model { gymSlogan = " ", hipsterSlogan = " ", updateGym = True, updateHipster = True, playSpeech = False }
    , Cmd.none
    )


sloganRequest : (Result Http.Error String -> msg) -> String -> Cmd msg
sloganRequest expecter url =
    Http.request
        { method = "GET"
        , headers = []
        , body = emptyBody
        , timeout = Just (2.0 * second)
        , url = url
        , tracker = Nothing
        , expect = expectString expecter
        }


update : Msg -> Model -> ( Model, Cmd Msg )
update msg (Model model) =
    case msg of
        RefreshGym _ ->
            ( Model model, sloganRequest NewGymResponse (apiRootUrl ++ "/gym") )

        RefreshHipster _ ->
            ( Model model, sloganRequest NewHipsterResponse (apiRootUrl ++ "/hipster") )

        ToggleSpeech ->
            ( Model { model | playSpeech = not model.playSpeech }, Cmd.none )

        NewGymResponse (Ok response) ->
            Model { model | gymSlogan = response } |> update (Speak response)

        NewGymResponse (Err err) ->
            ( Model model, Cmd.none )

        NewHipsterResponse (Ok response) ->
            ( Model { model | hipsterSlogan = response }, Cmd.none )

        NewHipsterResponse (Err err) ->
            ( Model model, Cmd.none )

        Speak text ->
            ( Model model
            , if model.playSpeech then
                speakIt text

              else
                Cmd.none
            )



-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions (Model model) =
    let
        gymUpdater =
            if model.updateGym then
                Time.every refreshInterval RefreshGym

            else
                Sub.none

        hipsterUpdater =
            if model.updateHipster then
                Time.every refreshInterval RefreshHipster

            else
                Sub.none
    in
    Sub.batch [ gymUpdater, hipsterUpdater ]



-- MAIN


main : Program Flags Model Msg
main =
    document
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        }
