'use strict';
import {Elm} from "./Main.elm";


const app = Elm.Main.init();
const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

app.ports.speakIt.subscribe(function (text) {
    if (!text) {
        return
    }
    const synthSupport = 'speechSynthesis' in window;
    const repRe = new RegExp('\\brep\\b', 'gi');
    const breakRe = new RegExp('break\\b', 'gi');

    function fixText(text) {
        // Google web TTS is crazy: "rep" = "waheedah"?
        return text && text.replace(repRe, 'repp').replace(breakRe, 'brake');
    }

    if (!synthSupport) {
        console.warn("Browser doesn't support Speech Synthesis.");
        return false;
    }
    const fixed = fixText(text);
    if (!fixed) {
        return false;
    }

    if (synthSupport) {
        // Synthesis support. Make your web apps talk!
        const msg = new SpeechSynthesisUtterance(fixed);
        msg.volume = isFirefox ? 0.7 : 0.9;
        msg.pitch = 0.7;  // 0 to 2
        msg.rate = 0.85;
        // Maximum hilarity on Chrome
        msg.lang = isFirefox ? 'en' : 'de';
        window.speechSynthesis.speak(msg);
        return true;
    }
});

document.addEventListener("DOMContentLoaded", function (event) {
    prettyPrint();

    // Mainly for Firefox
    document.querySelectorAll('a').forEach(function (el) {
        el.addEventListener('mouseup', function (e) {
            e.currentTarget.blur()
        });
    });

    // Mobile menu
    const elems = document.querySelectorAll('.sidenav');
    M.Sidenav.init(elems, {});

    // Set up full page stuff
    new fullpage('#fullpage', {
        anchors: ['home', 'why', 'gym', 'hipster', 'tech', 'api'],
        verticalCentered: false,
        animateAnchor: true,
        menu: '#main-nav ul',
        paddingTop: '3em',
        navigation: true,
        licenseKey: 'E9FA420D-F2054BF8-B0A1B7DC-4B163A8B',
        v2compatible: false,
        scrollingSpeed: 800,
        onLeave: function (last, next, direction) {
            // console.log("Left page", last.anchor, "for page", next.anchor);
            const mcb = document.getElementById('mcBain');
            const sav = document.getElementById('savant');

            if (last.anchor === "why") {
                mcb.style.opacity = 0;
            } else if (next.anchor === "why") {
                sav.style.opacity = 0;
                setTimeout(function () {
                    mcb.style.opacity = "1.0"
                }, 500);
            }
            if (last.anchor === "hipster") {
                sav.style.opacity = 0;
            } else if (next.anchor === "hipster") {
                sav.style.opacity = 0;
                setTimeout(function () {
                    sav.style.opacity = "1.0"
                }, 1000);
            } else if (["tech", "api"].indexOf(next.anchor) >= 0) {
                sav.style.opacity = 0;
                mcb.style.opacity = 0;
            }
        }
    });
});
